import React, { useState, useEffect } from 'react';

// libraries
import axios from 'axios';

import stats from 'analytics/matomo';
import { getCurrentLocale } from 'locales/i18n';

// hooks
import { useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Actions
import { setSession } from 'store/session';

// constants
import { HOT_SCRIMPS, ONBOARDING } from 'constants/routes';

function Login() {
  const { hasData } = useSelector((state) => state.session);
  const { hasCreatedPlayer } = useSelector((state) => state.player);

  const { t } = useTranslation(['general', 'errors']);

  const [error, setError] = useState('');

  const {
    getAccessTokenSilently, logout, user: auth0User,
  } = useAuth0();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signal] = useState(axios.CancelToken.source());

  useEffect(() => {
    const loadUser = async () => {
      try {
        const token = await getAccessTokenSilently();

        const data = {
          email: auth0User.email,
          language: getCurrentLocale(),
          // name: auth0User.nickname.charAt(0).toUpperCase() + auth0User.nickname.slice(1),
        };

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        const response = await axios.post(`${process.env.REACT_APP_API}/users`, data, config);

        const { user } = response.data;

        if (!user) {
          await logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
          return;
        }

        stats.setUserId(user.email);
        stats.push(['trackEvent', 'Account', 'Sign In']);

        dispatch(setSession(user));

        const redirectTo = JSON.parse(localStorage.getItem('redirectTo'));

        if (redirectTo) {
          localStorage.removeItem('redirectTo');
          navigate(redirectTo);
          return;
        }

        navigate(HOT_SCRIMPS);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        console.log(err);

        console.log(err.response);

        if (!err.response) {
          setError(t('errors:login_again_error'));
          return;
        }

        if (err.response.status === 500) {
          setError(t('errors:server_error'));
          return;
        }

        setError(t('errors:data_load_error'));
      }
    };

    if (!hasData) return;

    if (!hasCreatedPlayer) {
      navigate(ONBOARDING);
      return;
    }

    // Data Provider will take care of redirecting
    const redirectTo = JSON.parse(localStorage.getItem('redirectTo'));

    if (redirectTo) {
      localStorage.removeItem('redirectTo');
      navigate(redirectTo);
      return;
    }

    navigate(HOT_SCRIMPS);
    return;

    if (!hasData) {
      loadUser();
    } else {
      navigate(HOT_SCRIMPS);
    }

    return () => {
      signal.cancel();
    };
  }, [hasData, hasCreatedPlayer]);

  return (
    <div className="hero is-min-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          {
            error.length === 0
              ? (
                <p className="is-size-7">
                  {`${t('general:loading')}...`}
                </p>
              )
              : <p className="has-text-centered has-text-danger has-text-weight-semibold is-size-4">{error}</p>
          }
        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Login);
