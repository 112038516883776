import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasData: false,
  isLoading: true,
  showSidebar: true,

  name: '',
  language: 'en',
  timezone: 'Europe/Berlin',
  region: '',
  nationality: 'us',
  email: '',
  profilePic: '',
  cardPic: '',
  contact: {
    discord: '',
    steam: '',
    riot: '',
    epic: '',
    battlenet: '',
    twitter: '',
    tagsgg: '',
  },
  cookies: {
    showBanner: true,
    allowAnalytics: false,
  },
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action) => ({
      ...state,
      hasData: true,
      isLoading: false,
      ...action.payload,
      contact: {
        ...state.contact,
        ...action.payload.contact,
      },
    }),
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setNationality: (state, action) => {
      state.nationality = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setProfilePic: (state, action) => {
      state.profilePic = action.payload;
    },
    setCardPic: (state, action) => {
      state.cardPic = action.payload;
    },
    setContact: (state, action) => {
      // we can copy it like so because it is a shallow object
      const contact = { ...state.contact };
      contact[action.payload.type] = action.payload.name;

      return {
        ...state,
        contact,
      };
    },
    addContact: (state, action) => ({
      ...state,
      contact: [...state.contact, action.payload],
    }),
    removeContact: (state, action) => ({
      ...state,
      contact: state.contact.map((contact) => contact.id !== action.payload),
    }),
    setCookieBanner: (state, action) => {
      state.cookies.showBanner = action.payload;
    },
    setAnalyticsSettings: (state, action) => {
      state.cookies.allowAnalytics = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setProfile: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      };
    },
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
    clearSession: () => initialState,
  },
});

export const {
  setSession,
  clearSession,

  addContact,
  removeContact,
  setContact,

  setCookieBanner,
  setAnalyticsSettings,

  setName,
  setProfile,
  setLanguage,
  setTimezone,
  setRegion,
  setNationality,
  setProfilePic,
  setCardPic,
  setShowSidebar,
} = sessionSlice.actions;

export default sessionSlice.reducer;
