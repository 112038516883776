import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

function BasicSeedingDisplay() {
  const { t } = useTranslation(['general']);
  return (
    <div className="box is-flex flex-direction-column has-content-centered py-6 mx-6">
      <p className="is-size-4 has-text-weight-bold">
        {t('general:seeding_in_progress')}
      </p>
      <p className="mt-4">
        {`${t('general:please_stand_by')}...`}
      </p>
    </div>
  );
}

export default BasicSeedingDisplay;
