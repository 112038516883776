import React, { useLayoutEffect, useMemo, useState } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import LogoBottomDisplay from 'components/utils/LogoBottomDisplay';
import TournamentMatchHandler from './TournamentMatchHandler';
import TournamentChatHandler from './TournamentChatHandler';
import TournamentSidebarSocketHandler from './TournamentSidebarSocketHandler';

function TournamentSidebar() {
  const { sidebarContent, participant, staffPermissions } = useTournament();

  // either 'details' or 'chat'
  const [pageToShow, setPageToShow] = useState('details');

  useLayoutEffect(() => {
    setPageToShow('details');
  }, [sidebarContent.data]);

  const canJoinChat = useMemo(() => {
    const { participants: matchParticipants } = sidebarContent.data;

    if (!matchParticipants || matchParticipants.length === 0) return false;

    if (staffPermissions.canManageMatches) {
      return true;
    }

    if (!participant.publicId) return false;

    const isInMatch = matchParticipants.some((p) => p.publicId === participant.publicId);

    if (!isInMatch) {
      return false;
    }

    return true;
  }, [participant, sidebarContent, staffPermissions]);

  if (!sidebarContent.type) return null;

  let content;

  if (sidebarContent.type !== 'match') return null;

  if (pageToShow === 'chat') content = <TournamentChatHandler />;
  if (pageToShow === 'details' || !canJoinChat) content = <TournamentMatchHandler />;

  return (
    <div
      id="tournamentSidebar"
      className=" p-0 is-narrow has-width-350 has-fullheight"
      style={{ zIndex: 1000 }}
    >
      <TournamentSidebarSocketHandler />
      <div className="is-fixed">
        <div
          className="is-fullheight has-width-350 has-background-dark-blue p-0 has-border-darkest-light"
        >
          <div
            className="is-flex flex-direction-column has-fullheight has-fullwidth is-relative"
          >

            <div
              className="is-relative has-fullheight has-overflow-y-auto"
              style={{ zIndex: 1000 }}
            >
              { content }
            </div>

            <div className="has-margin-top-auto">
              <div className="is-relative">
                <div className="has-background-dark pt-2 pb-2 is-relative" style={{ zIndex: 100 }}>
                  <div className="columns is-mobile">

                    <div className="column has-text-centered">
                      <button
                        type="button"
                        className="cleanButton"
                        onClick={() => setPageToShow('details')}
                      >
                        <i className="fas fa-gamepad has-text-grey is-size-4" />
                      </button>
                    </div>

                    {
                        canJoinChat && (
                          <div className="column has-text-centered">
                            <button
                              type="button"
                              className="cleanButton"
                              onClick={() => setPageToShow('chat')}
                            >
                              <i className="fas fa-comments has-text-grey is-size-4" />
                            </button>
                          </div>
                        )
                      }

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

/*
<div
                      style={{ zIndex: 10 }}
                      className="is-relative"
                    >
                      <LogoBottomDisplay />
                    </div>
*/

export default TournamentSidebar;
