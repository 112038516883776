import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';

// HOCs
import { withAuthenticationRequired } from '@auth0/auth0-react';

// Hooks
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Constants
import { PLAYER_PROFILE, LOGIN } from 'constants/routes';

// Components
import Popup from 'reactjs-popup';
import PlayerCreator from './PlayerCreator';
import PlayerDetailsEditor from './PlayerDetailsEditor';
import PlayerImageEditor from './PlayerImageEditor';

function Onboarding() {
  const { hasData: hasUserData } = useSelector((state) => state.session);
  const { hasCreatedPlayer } = useSelector((state) => state.player);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [onboardingPage, setOnboardingPage] = useState('playerName');

  useLayoutEffect(() => {
    // if we dont have data, go to login
    if (!hasUserData) navigate(LOGIN);

    // if Player has already been created, go to home
    // (only check for first mount)
    // important: only run this effect if hasUserData changes, not if hasCreatedPlayer changes
    // because the first onboarding page will create a player, and want to redirect only after onboarding is done
    if (hasCreatedPlayer) navigate(PLAYER_PROFILE);
  }, []);

  useEffect(() => {
    if (onboardingPage === 'done') navigate(PLAYER_PROFILE);
  }, [onboardingPage]);

  const renderedOnboardingPopup = useMemo(() => {
    switch (onboardingPage) {
      case 'playerName':
        return <PlayerCreator onDone={() => setOnboardingPage('playerDetails')} />;
      case 'playerDetails':
        return <PlayerDetailsEditor onDone={() => setOnboardingPage('playerImage')} />;
      case 'playerImage':
        return <PlayerImageEditor onDone={() => setOnboardingPage('done')} />;
      default:
        return null;
    }
  }, [onboardingPage]);

  return (
    <div className="is-min-fullheight pb-6">
      <Popup
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        modal
        open
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {
            () => (
              <div className="is-fullwidth has-fullheight">
                <div className="columns is-centered has-overflow-y-auto" style={{ minHeight: '70vh' }}>
                  <div className="column has-max-width-900 is-8-desktop is-8-tablet is-12-mobile">
                    { renderedOnboardingPopup }
                  </div>
                </div>
              </div>
            )
           }
      </Popup>
    </div>
  );

  return (
    <div className="is-min-fullheight pb-6">

      <div className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight">

        <div className="column is-10-desktop is-10-tablet is-11-mobile">

          <div
            className="pt-6 pb-6"
          >
            <div className="box boxshadow br-100 has-text-weight-semibold is-inline-flex has-content-centered py-2 px-4">
              <i className="fas fa-search" />
              <p
                className="ml-2 is-size-5 has-line-height-full"
              >
                {t('general:welcome')}
              </p>
            </div>

            <div className="">
              <p
                className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-weight-semibold"
              >
                {t('general:welcome')}
              </p>
              <p
                className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-grey"
              >
                {t('general:welcome_description_short')}
              </p>
            </div>

            <div className="columns is-multiline">

              <div className="column is-9-desktop is-9-tablet is-12-mobile">
                Filter
              </div>

              <div className="column">
                Create
              </div>

            </div>

          </div>

          <div className="columns is-multiline">

            <div className="column is-9-desktop is-9-tablet is-12-mobile">

              <p className="mb-2 has-text-weight-semibold">
                { t('general:overview')}
              </p>
              <PlayerCreator />

            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Onboarding);
