import React, { useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Libraries
import PropTypes from 'prop-types';

// Components
import CardPicDisplay from 'components/utils/CardPicDisplay';

// Shows the match and draws the lines to the next match, if it exists
function MatchDisplay(props) {
  const {
    match: matchData,
    childMatch: childMatchData,
    matchNumber: displayMatchNumber,
  } = props;

  const [clickTime, setClickTime] = useState(0);

  const { t } = useTranslation(['general']);

  const { participant, sidebarContent, setSidebarContent } = useTournament();

  const {
    participants, result, round: roundNumber, match: matchNumber, status: matchStatus,
  } = matchData;

  const showMatchInSidebar = (e) => {
    // calculate click time, to only trigger if its actually a click, not a drag
    const time = new Date().getTime();

    if (time - clickTime >= 150) {
      e.preventDefault();
      return;
    }

    if (sidebarContent.type === 'match' && sidebarContent.data.publicId === matchData.publicId) {
      return;
    }

    setSidebarContent({
      type: 'match',
      data: { publicId: matchData.publicId },
    });
  };

  const participantData = useMemo(() => {
    let _participants = participants;

    if (_participants.length === 0) {
      _participants = [
        {
          cardPic: '',
          name: '',
          isDisqualified: false,
        },
        {
          cardPic: '',
          name: '',
          isDisqualified: false,
        },
      ];
    } else if (_participants.length === 1) {
      _participants.push({
        cardPic: '',
        name: '',
        isDisqualified: false,
      });
    }

    return _participants;
  }, [participants]);

  const renderedParticipants = useMemo(() => participantData.map((_participant, index) => {
    const gamesWon = result.gameResults.filter((_result) => _result.participant === _participant.publicId).length;

    if (!_participant.publicId) {
      return (
        <div
          key={index}
          className={`columns has-height-48 ${index === 0 ? 'has-border-bottom-grey-dark' : ''}`}
        />
      );
    }

    return (
      <div
        key={_participant.publicId}
        className={`columns has-height-48 ${index === 0 ? 'has-border-bottom-grey-dark' : ''}`}
      >
        <div className="column is-narrow has-width-30 p-0 is-flex has-content-centered">
          <CardPicDisplay
            img={_participant.cardPic}
            url={_participant.participantType === 'team' ? '/images/teams/card_pictures/' : undefined}
          />
        </div>
        <div className="column p-0 is-flex has-content-centered-vertically px-3 abbreviate-text">
          <p className={_participant.isDisqualified ? 'has-text-danger' : ''}>
            {`${_participant.name}`}
          </p>
        </div>
        <div className="column is-2 py-0 is-flex has-content-centered mx-1">
          <p className="has-text-weight-bold has-background-darkest px-3 py-2 br-5">
            {`${gamesWon}`}
          </p>

        </div>
      </div>
    );
  }), [participantData]);

  const HEIGHT = 145;

  const calculateVerticalStartingPoint = (round, match) => {
    let roundIndex = round - 1;

    if (roundIndex < 0) roundIndex = 0;

    const heightAdjustment = round >= 1 ? 2 : 1;

    // special case for preliminary rounds
    if (round === 0) {
      if (!childMatchData) return 0;

      const { match: childMatchNumber, participants: childParticipants } = childMatchData;

      const childMatchIndex = childMatchNumber - 1;

      let preliminaryTop = (childMatchIndex * 2) * HEIGHT;

      const matchIndex = match - 1;

      // if the following match has no participants, and trhis is the second match of the round, set it below the other match
      if (matchIndex % 2 === 0 && !childParticipants[0]) {
        preliminaryTop += HEIGHT;
      }

      // if there is already one participant, set it below the other match
      if (childParticipants[0]) {
        preliminaryTop += HEIGHT;
      }

      return preliminaryTop;
    }

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) / 2 - HEIGHT / 2;
  };

  const calculateHeightIncrease = (round, match) => {
    let roundIndex = round - 1;
    const matchIndex = match - 1;

    if (roundIndex < 0) roundIndex = 0;

    if (round === 0) {
      return 0;
    }

    const heightAdjustment = round >= 1 ? 2 : 1;

    // const heightPreviousMatches = matchIndex * HEIGHT;

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) * matchIndex;
  };

  const position = useMemo(() => calculateHeightIncrease(roundNumber, matchNumber) + calculateVerticalStartingPoint(roundNumber, matchNumber), [roundNumber, matchNumber]);

  const matchBorder = useMemo(() => {
    const userPlaysInMatch = participants.some((_participant) => _participant.publicId === participant.publicId);

    if (matchStatus === 'pending') {
      if (userPlaysInMatch) return 'has-border-playerboard-glow';

      return 'has-border-primary-glow';
    }

    return 'has-border-darker';
  }, [matchStatus, participant, participants]);

  return (
    <div
      className="has-height-125 has-width-250 is-absolute"
      style={{ top: `${position}px`, margin: '20px 0' }}
    >
      <div className="has-text-centered has-text-grey mb-1">
        <p className="">
          {`${t('general:match')} ${displayMatchNumber}`}
        </p>
      </div>
      <div
        className={`br-7 has-height-96 has-overflow-hidden ${matchBorder}`}
      >
        <button
          type="button"
          className="cleanButton has-fullwidth p-0 m-0"
          onMouseDown={() => setClickTime(new Date().getTime())}
          onClick={showMatchInSidebar}
        >
          <div className="box p-0 m-0">
            {renderedParticipants}
          </div>
        </button>
      </div>
    </div>

  );
}

MatchDisplay.propTypes = {
  match: PropTypes.shape({
    participants: PropTypes.arrayOf(PropTypes.shape({})),
    result: PropTypes.shape({}),
    round: PropTypes.number,
    match: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
};

export default MatchDisplay;
