import { useAuth0 } from '@auth0/auth0-react';
import LoginPopup from 'components/utils/LoginPopup';
import { LOGIN } from 'constants/routes';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Popup from 'reactjs-popup';
import CreatedPlayerboardDisplay from './CreatedPlayerboardDisplay';
import LoadMoreButton from './LoadMoreButton';
import PlayerCard from './PlayerCard';
import PlayerDetails from './PlayerDetails';

function PlayerboardDisplay() {
  const { timezone, language } = useSelector((state) => state.session);
  const { entries, userEntry } = useSelector((state) => state.playerboard);

  const { t } = useTranslation(['general']);

  const { isAuthenticated } = useAuth0();

  const [isOpen, setOpen] = useState(false);

  const renderedEntries = useMemo(() => entries.map((entry, index) => (
    <Popup
      trigger={(
        <div className="column is-narrow my-3">
          <PlayerCard index={index} />

        </div>
      )}
      position="top center"
      keepTooltipInside="#root"
      closeOnDocumentClick
      closeOnEscape
      modal
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      key={index}
    >
      {
        (close) => (
          isAuthenticated
            ? <PlayerDetails index={index} close={close} />
            : <LoginPopup close={close} />
        )
      }

    </Popup>

  )), [entries, timezone, language, isOpen]);

  return (
    <div>
      <div className="mb-6 mt-2 columns has-text-centered-mobile is-centered-mobile is-multiline">
        {
          isAuthenticated && (<CreatedPlayerboardDisplay />)
        }
        { renderedEntries }
      </div>
      <div
        className="has-text-centered"
      >
        <LoadMoreButton />
      </div>

    </div>
  );
}

export default PlayerboardDisplay;
