import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useTournament } from 'components/tournaments/TournamentPage';

// Assets
import header from 'assets/images/backgrounds/tournament_editor_header.png';

// Styles
import styles from './styles.module.scss';

// Components
import TournamentTitleEditor from './TournamentTitleEditor';
import TournamentSubtitleEditor from './TournamentSubtitleEditor';
import TournamentDescriptionEditor from './TournamentDescriptionEditor';
import TournamentPrizePoolEditor from './TournamentPrizePoolEditor';
import TournamentDeadlinesEditor from './TournamentDeadlinesEditor';
import TournamentPlatformSelector from './TournamentPlatformSelector';
import TournamentDeletionHandler from './TournamentDeletionHandler';
import TournamentRegionSelector from './TournamentRegionSelector';
import TournamentGameSettingsHandler from './TournamentGameSettingsHandler';
import TournamentCardPicEditor from './TournamentCardPicEditor';
import TournamentBannerPicEditor from './TournamentBannerPicEditor';
import TournamentRulesEditor from './TournamentRulesEditor';
import TournamentOrganizerEditor from './TournamentOrganizerEditor';
import TournamentMainSponsorEditor from './TournamentMainSponsorEditor';
import TournamentSponsorsEditor from './TournamentSponsorsEditor';
import TournamentSettingsEditor from './TournamentSettingsEditor';
import TournamentStaffHandler from './TournamentStaffHandler';
import TournamentPublishHandler from './TournamentPublishHandler';

function TournamentEditor() {
  const { setContentToShow } = useTournament();
  const { t } = useTranslation(['general']);

  return (
    <div className="">
      <div className="px-5">
        <img src={header} alt="header" />
      </div>

      <div className="mt-6 px-6">
        <button
          type="button"
          className="button has-text-white is-primary is-small mb-5 py-1 px-6"
          onClick={() => setContentToShow('info')}
          aria-label={t('general:back')}
        >
          <i className="fas fa-arrow-left is-size-5" />
        </button>

        <h1
          className="has-text-primary has-text-weight-bold is-size-1"
        >
          {t('general:tournament_setup')}
        </h1>

        <div className="mt-6">
          <section>
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-5">
              <i className="fas fa-play mr-4 has-text-primary" />
              {t('general:description')}
            </h2>

            <div className={styles.greenWrapper}>
              <div className="columns is-multiline">
                <div className="column is-7">

                  <div className="">
                    <TournamentTitleEditor />
                  </div>

                  <div className="mt-5">
                    <TournamentSubtitleEditor />
                  </div>

                  <div className="mt-5">
                    <TournamentDescriptionEditor />
                  </div>

                </div>
                <div className="column is-offset-1">

                  <div className="">
                    <TournamentPlatformSelector />
                  </div>

                  <div className="mt-5">
                    <TournamentRegionSelector />
                  </div>

                  <div className="mt-5">
                    <TournamentPrizePoolEditor />
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="mt-6">
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-5">
              <i className="fas fa-play mr-4 has-text-primary" />
              {t('general:game_and_performance_classes')}
            </h2>

            <div className={styles.greenWrapper}>
              <div className="columns is-multiline">
                <div className="column">

                  <div className="">
                    <TournamentGameSettingsHandler />
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="mt-6">
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-1">
              <i className="fas fa-play mr-4 has-text-primary" />
              {t('general:tournament_specification')}
            </h2>
            <p className="mb-5">
              {`(${t('general:can_only_be_changed_in_tournament_draft')})`}
            </p>

            <div className={styles.greenWrapper}>
              <TournamentSettingsEditor />
            </div>
          </section>

          <section className="mt-6">
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-5">
              <i className="fas fa-play mr-4 has-text-hotscrimps" />
              {t('general:times')}
            </h2>

            <div className={styles.orangeWrapper}>
              <div className="columns is-multiline">
                <div className="column">

                  <div className="">
                    <TournamentDeadlinesEditor />
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="mt-6">
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-5">
              <i className="fas fa-play mr-4 has-text-playerboard" />
              {t('general:logos_and_display')}
            </h2>

            <div className={styles.blueWrapper}>
              <div className="columns is-multiline">
                <div className="column">

                  <div className="columns">
                    <div className="column is-narrow">
                      <TournamentCardPicEditor />
                    </div>

                    <div className="column is-offset-1">
                      <TournamentBannerPicEditor />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="mt-6">
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-5">
              <i className="fas fa-play mr-4 has-text-playerboard" />
              {t('general:organizer_and_sponsors')}
            </h2>

            <div className={styles.blueWrapper}>
              <div className="columns is-multiline">
                <div className="column is-narrow">
                  <TournamentOrganizerEditor />
                </div>
                <div className="column is-narrow">
                  <TournamentMainSponsorEditor />
                </div>
                <TournamentSponsorsEditor />
              </div>
            </div>
          </section>

          <section className="mt-6">
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-5">
              <i className="fas fa-play mr-4 has-text-competitive" />
              {t('general:admin_selection')}
            </h2>

            <div className={styles.redWrapper}>
              <TournamentStaffHandler />
            </div>
          </section>

          <section className="mt-6 pb-6">
            <h2 className="is-size-3 has-text-standard has-text-weight-light mb-5">
              <i className="fas fa-play mr-4 has-text-competitive" />
              {t('general:tournament_rules_and_information')}
            </h2>

            <div className={styles.redWrapper}>
              <TournamentRulesEditor />
            </div>
          </section>

          <TournamentPublishHandler />

          <section className="mt-6 has-text-right">
            <TournamentDeletionHandler />
          </section>

        </div>
      </div>
    </div>
  );
}

export default TournamentEditor;
