const MAX_AMOUNT_TOURNAMENTS = 100;

const MAX_TOURNAMENT_TITLE_LENGTH = 100;
const MAX_TOURNAMENT_SUBTITLE_LENGTH = 100;
const MAX_TOURNAMENT_DESCRIPTION_LENGTH = 500;

const MAX_TOURNAMENT_RULES = 20;
const MAX_TOURNAMENT_RULE_NAME_LENGTH = 50;
const MAX_TOURNAMENT_RULE_CONTENT_LENGTH = 100000;

const MAX_TOURNAMENT_PRIZE_POOL_LENGTH = 200;

// Sponsors
const MAX_TOURNAMENT_MAIN_SPONSOR_NAME_LENGTH = 100;
const MAX_TOURNAMENT_MAIN_SPONSOR_URL_LENGTH = 100;
const MAX_TOURNAMENT_SPONSOR_NAME_LENGTH = 100;
const MAX_TOURNAMENT_SPONSOR_URL_LENGTH = 100;
const MAX_TOURNAMENTS_SPONSORS = 10;

// Social Links
const MAX_TOURNAMENT_SOCIAL_LINK_URL_LENGTH = 200;

const MAX_TOURNAMENT_STAFF_MEMBERS = 300;
const MAX_TOURNAMENT_TEAMS = 1000;

const TOURNAMENT_TYPES = [
  'single_elimination',
  'double_elimination',
  'round_robin',
  'swiss',
  'scrim_day',
];

const BEST_OF_MATCHES = [1, 3, 5, 7];
const BEST_OF_FINALS = [1, 3, 5, 7];

const TOURNAMENT_PHASES = ['draft', 'upcoming', 'registration_open', 'check_in', 'seeding', 'in_progress', 'finished'];

const ROLES = [
  {
    type: 'creator',
    permissions: ['inviteStaff', 'kickAdmin', 'kickStaffMember', 'kickParticipant', 'banParticipant', 'updateTournamentInfo', 'deleteTournament', 'setStaffPermissions', 'setStaffTournamentRole', 'manageMatches', 'manageBracket', 'disqualifyParticipant'],
  },
  {
    type: 'admin',
    permissions: ['updateTournamentInfo', 'kickStaffMember', 'kickParticipant', 'banParticipant', 'leaveStaff', 'manageMatches', 'manageBracket', 'disqualifyParticipant'],
  },
  {
    type: 'staffMember',
    permissions: ['leaveStaff'],
  },
];

export const STAFF_TOURNAMENT_ROLES = [
  {
    type: '',
  },
  {
    type: 'referee',
  },
  {
    type: 'organizer',
  },
];

const PARTICIPANT_ROLES = [
  {
    type: 'captain',
    permissions: ['checkIn', 'setGameOutcome', 'deregister'],
  },
  {
    type: 'co-captain',
    permissions: ['checkIn', 'setGameOutcome', 'deregister'],
  },
  {
    type: 'player',
    permissions: [],
  },
];

const getStaffPermissionsForRole = (role) => {
  const roleObj = ROLES.find((r) => r.type === role);

  if (!roleObj) throw new Error('Role not found');

  return roleObj.permissions;
};

const hasStaffPermission = (role, permission) => {
  const permissions = getStaffPermissionsForRole(role);

  return permissions.includes(permission);
};

const getParticipantPermissionsForRole = (role) => {
  const roleObj = PARTICIPANT_ROLES.find((r) => r.type === role);

  if (!roleObj) throw new Error('Role not found');

  return roleObj.permissions;
};

const hasParticipantPermission = (role, permission) => {
  const permissions = getParticipantPermissionsForRole(role);

  return permissions.includes(permission);
};

export {
  MAX_AMOUNT_TOURNAMENTS,
  MAX_TOURNAMENT_TITLE_LENGTH,
  MAX_TOURNAMENT_SUBTITLE_LENGTH,
  MAX_TOURNAMENT_DESCRIPTION_LENGTH,
  MAX_TOURNAMENT_RULES,
  MAX_TOURNAMENT_RULE_NAME_LENGTH,
  MAX_TOURNAMENT_RULE_CONTENT_LENGTH,

  MAX_TOURNAMENT_MAIN_SPONSOR_NAME_LENGTH,
  MAX_TOURNAMENT_MAIN_SPONSOR_URL_LENGTH,
  MAX_TOURNAMENT_SPONSOR_NAME_LENGTH,
  MAX_TOURNAMENT_SPONSOR_URL_LENGTH,
  MAX_TOURNAMENTS_SPONSORS,

  MAX_TOURNAMENT_SOCIAL_LINK_URL_LENGTH,
  MAX_TOURNAMENT_PRIZE_POOL_LENGTH,

  MAX_TOURNAMENT_STAFF_MEMBERS,
  MAX_TOURNAMENT_TEAMS,

  TOURNAMENT_TYPES,
  TOURNAMENT_PHASES,
  BEST_OF_MATCHES,
  BEST_OF_FINALS,

  ROLES,

  getStaffPermissionsForRole,
  hasStaffPermission,
  getParticipantPermissionsForRole,
  hasParticipantPermission,
};
