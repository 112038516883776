import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Assets

import icon from 'assets/images/base/icon_no_text.png';
import csgoIcon from 'assets/images/games/csgo/csgo_landing_icon.png';
import valorantIcon from 'assets/images/games/val/valorant_landing_icon.png';
import rlIcon from 'assets/images/games/rl/rl_landing_icon.png';
import owIcon from 'assets/images/games/ow/ow_landing_icon.png';
import rssIcon from 'assets/images/games/rss/rss_landing_icon.png';
import lolIcon from 'assets/images/games/lol/lol_landing_icon.png';

import playerboardScreenshot from 'assets/images/base/playerboard_screenshot.png';
import hotscrimpsScreenshot from 'assets/images/base/hotscrimps_screenshot.png';
// import slipCardsScreenshot from 'assets/images/base/slipcards_screenshot.png';

// components
import CookieBanner from 'components/layout/CookieBanner';
import TriangleDisplay from 'components/utils/TriangleDisplay';
import { Link } from 'react-router-dom';

// constants
import {
  LOGIN, PLAYERBOARD, HOT_SCRIMPS,
} from 'constants/routes';

// styles

import styles from './styles.module.scss';

function Landing() {
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation(['general', 'hotscrimps', 'slips', 'playerboard']);

  return (
    <>
      <CookieBanner />
      <TriangleDisplay />
      <div className="is-min-fullheight">

        <div className="mb-6 pb-6">
          <div className="columns is-centered">
            <div className="column is-10-desktop is-10-tablet is-12-mobile mt-6">

              <div className="box boxshadow p-5">
                <div className="has-text-centered">

                  <div className="mb-5 mt-5">
                    <div>
                      <img src={icon} className="has-fullwidth has-max-width-300" alt="icon" />
                    </div>
                    <div>
                      <h2
                        className="is-size-1-desktop is-size-2-tablet is-size-3-mobile mt-5"
                      >
                        Never scrim alone
                      </h2>
                    </div>
                  </div>

                  <div className="has-max-width-500 has-margin-auto mt-4has-text-centered">
                    <p>
                      {t('general:landing_introduction')}
                    </p>
                  </div>

                  {
                    !isAuthenticated && (
                      <div>
                        <Link
                          to={LOGIN}
                          type="button"
                          className="button has-background-primary has-text-weight-semibold mt-5"
                        >
                          {t('general:register_now')}
                        </Link>
                      </div>
                    )
                  }

                  <div
                    className="has-text-centered mt-6"
                  >
                    <i className="fas fa-arrow-circle-down has-text-grey-darker is-size-4" />
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="has-background-primary py-6 px-3 my-6">
          <div className="columns is-centered is-multiline">
            <div className="column is-narrow mx-6 is-flex has-content-centered">
              <img src={csgoIcon} alt="" className="has-width-130 has-height-130" />
            </div>

            <div className="column is-narrow mx-6 is-flex has-content-centered">
              <img src={rlIcon} alt="" className="has-width-90 has-height-90" />
            </div>
            <div className="column is-narrow mx-6 is-flex has-content-centered">
              <img src={rssIcon} alt="" className="has-width-90 has-height-90" />
            </div>
            <div className="column is-narrow mx-6 is-flex has-content-centered">
              <img src={lolIcon} alt="" className="has-width-90 has-height-90" />
            </div>
            <div className="column is-narrow mx-6 is-flex has-content-centered">
              <img src={owIcon} alt="" className="has-width-90 has-height-90" />
            </div>

            <div className="column is-narrow mx-6 is-flex has-content-centered">
              <img src={valorantIcon} alt="" className="has-width-110 has-height-110" />
            </div>
          </div>
        </div>

        <div className="has-text-centered my-6 py-6">
          <h3
            className="is-size-2-desktop is-size-3-tablet is-size-4-mobile"
          >
            {t('general:our_modules')}
          </h3>
        </div>

        <div className="my-6">
          <div className="has-text-centered my-5">
            <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile">
              {t('playerboard:playerboard')}
            </h2>
          </div>
          <div className="columns has-fullheight is-centered is-vcentered">
            <div className="column is-6 has-max-width-800">
              <div className={`${styles.browserWindowPlayerboard} boxshadow`}>
                <div className={styles.topBar}>
                  <div className="columns has-fullheight is-marginless is-paddingless is-mobile">
                    <div className="column is-4 is-paddingless has-text-left">
                      <div className={styles.circles}>
                        <div className={styles.circle} />
                        <div className={styles.circle} />
                        <div className={styles.circle} />
                      </div>
                    </div>
                    <div className="column is-4 is-paddingless">
                      <div className={styles.searchBar} />
                    </div>
                    <div className="column is-4 is-paddingless" />
                  </div>
                </div>
                <img src={playerboardScreenshot} alt="Player Bowl Screenshot" className="" />
              </div>
            </div>
            <div className="column is-4 has-min-width-300 is-offset-1">

              <div className="box p-6">
                <h2 className="is-size-3-desktop is-size-4-tablet is-size-5-mobile">
                  {t('general:lft_module')}
                </h2>
                <p className="my-3 is-size-4 is-size-5-tablet is-size-6-mobile">
                  {t('general:landing_playerboard_description')}
                </p>
                <Link
                  to={PLAYERBOARD}
                  type="button"
                  className="button has-background-primary has-text-weight-semibold my-3"
                >
                  {t('general:try_out_now')}
                </Link>
              </div>

            </div>
          </div>
        </div>

        <div className="my-6 pt-6">
          <div className="has-text-centered my-5">
            <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile">
              {t('hotscrimps:hotscrimps')}
            </h2>
          </div>
          <div className="columns has-fullheight is-centered is-vcentered">

            <div className="column is-4 has-min-width-300">
              <div className="box p-6">
                <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile">
                  {t('general:lfs_module')}
                </h2>
                <p className="my-3 is-size-4 is-size-5-tablet is-size-6-mobile">
                  {t('general:landing_hotscrimps_description')}
                </p>
                <Link
                  to={HOT_SCRIMPS}
                  type="button"
                  className="button has-background-primary has-text-weight-semibold my-3"
                >
                  {t('general:try_out_now')}
                </Link>
              </div>
            </div>

            <div className="column is-6 has-max-width-800 is-offset-1">
              <div className={`${styles.browserWindowHotScrimps} boxshadow`}>
                <div className={styles.topBar}>
                  <div className="columns has-fullheight is-marginless is-paddingless is-mobile">
                    <div className="column is-4 is-paddingless has-text-left">
                      <div className={styles.circles}>
                        <div className={styles.circle} />
                        <div className={styles.circle} />
                        <div className={styles.circle} />
                      </div>
                    </div>
                    <div className="column is-4 is-paddingless">
                      <div className={styles.searchBar} />
                    </div>
                    <div className="column is-4 is-paddingless" />
                  </div>
                </div>
                <img src={hotscrimpsScreenshot} alt="Hot Scrimps Screenshot" className="" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="has-text-centeredpt-6"
        >
          <div className="columns is-centered">
            <div className="column is-10-desktop is-10-tablet is-12-mobile mt-6">

              <div className="box boxshadow p-6">
                <div className="has-text-centered">

                  <h4
                    className="is-size-3-desktop is-size-4-touch has-text-weight-semibold mb-3"
                  >
                    {t('general:landing_footer_headline')}
                  </h4>
                  <p
                    className="my-3 is-size-4 is-size-5-tablet is-size-6-mobile"
                  >
                    {t('general:landing_footer_description')}
                  </p>

                  <p
                    className="mt-2 is-size-5"
                  >
                    Never scrim alone.
                  </p>

                  {
                    !isAuthenticated && (
                      <Link
                        to={LOGIN}
                        type="button"
                        className="button has-background-primary has-text-weight-semibold mt-5"
                      >
                        {t('general:register_now')}
                      </Link>
                    )
                  }

                  <div className="mt-5 pt-3">
                    <img src={icon} alt="Scrimps icon" className="has-width-100" />
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </>
  );
}
/*
<div className="my-6 pt-6">
          <div className="has-text-centered my-5">
            <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile">
              {t('slips:slip_cards')}
            </h2>
          </div>
          <div className="columns has-fullheight is-centered is-vcentered">
            <div className="column is-6 has-max-width-800">
              <div className={`${styles.browserWindow} boxshadow`}>
                <div className={styles.topBar}>
                  <div className="columns has-fullheight is-marginless is-paddingless is-mobile">
                    <div className="column is-4 is-paddingless has-text-left">
                      <div className={styles.circles}>
                        <div className={styles.circle} />
                        <div className={styles.circle} />
                        <div className={styles.circle} />
                      </div>
                    </div>
                    <div className="column is-4 is-paddingless">
                      <div className={styles.searchBar} />
                    </div>
                    <div className="column is-4 is-paddingless" />
                  </div>
                </div>
                <img src={slipCardsScreenshot} alt="Slip Cards Screenshot" className="" />
              </div>
            </div>

            <div className="column is-4 has-min-width-300 is-offset-1">
              <div className="box p-6">
                <h2 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile">
                  {t('general:match_your_opponent')}
                </h2>
                <p className="my-3 is-size-4 is-size-5-tablet is-size-6-mobile">
                  {t('slips:slips_description_landing')}
                </p>
                <Link
                  to={LOGIN}
                  type="button"
                  className="button has-background-secondary has-text-weight-semibold my-3"
                >
                  {t('general:register_now')}
                </Link>
              </div>
            </div>

          </div>
        </div>
*/

export default Landing;
