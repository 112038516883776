import React, {
  useState, useEffect, useContext, useMemo, useLayoutEffect,
  useCallback,
} from 'react';

// Libraries
import { hasStaffPermission, hasParticipantPermission } from 'constants/tournaments';
import { setShowSidebar } from 'store/session';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

// Constants
import { TOURNAMENT_LIST } from 'constants/routes';

// Components
import TournamentHeader from './TournamentHeader';
import TournamentEditor from './TournamentEditor';
import TournamentPartnerDisplay from './TournamentPartnerDisplay';
import TournamentPhaseHandler from './TournamentPhaseHandler';
import TournamentAdminPanel from './TournamentAdminPanel';
import TournamentSidebar from './TournamentSidebar';
import TournamentSocketProvider from './TournamentSocketProvider';

const INITIAL_STAFF_PERMISSIONS = {
  canUpdateTournamentInfo: false,
  canDeleteTournament: false,
  canInviteStaff: false,
  canKickAdmin: false,
  canKickStaffMember: false,
  canKickParticipant: false,
  canBanParticipant: false,
};

const INITIAL_PARTICIPANT_PERMISSIONS = {
  canCheckIn: false,
  canSetGameOutcome: false,
  canDeregister: false,
};

const TournamentContext = React.createContext({});

/**
 * ´The page to display a Tournament in all states, with all dll details
 *
 * @returns {React.Component} - The component
 */
function TournamentPage() {
  const dispatch = useDispatch();

  const [info, setInfo] = useState({
    region: '',
    title: '',
    subtitle: '',
    description: '',
    cardPic: '',
    bannerPic: '',
    rules: '',
    organizer: {
      name: '',
      website: '',
      cardPic: '',
    },
    mainSponsor: {
      name: '',
      website: '',
      cardPic: '',
    },
    sponsors: [],
    prizePool: '',
    socialLinks: {
      website: '',
      discord: '',
      twitter: '',
      instagram: '',
      twitch: '',
      youtube: '',
      facebook: '',
    },
  });

  const [game, setGame] = useState({
    tag: '',
    mode: '',
    platform: '',
    mmrRange: {
      start: -1,
      end: -1,
    },
  });

  const [tournamentSettings, setTournamentSettings] = useState({
    tournamentType: 'single_elimination',
    maxParticipants: -1,
    maxGroups: -1,
    bestOfMatches: 1,
    bestOfFinals: 1,
  });
  const [currentPhase, setCurrentPhase] = useState('draft');

  const [staff, setStaff] = useState([]);

  /*
  {
    publicId: '',
    name: '',
    cardPic: '',
    averageMmrPlayers: -1,
    participantType: '',
    players: [],
  }
  */
  // all participants in the tournament (we load 20 at a time)
  const [participants, setParticipants] = useState([]);
  // the current (logged in) participant
  const [participant, setParticipant] = useState({
    name: '',
    cardPic: '',
    publicId: '',
    participantPublicId: '',
    participantType: '',
    joinedAt: new Date(),
    isCheckedIn: false,
  });

  const [deadlines, setDeadlines] = useState({
    registration: {
      start: null,
      end: null,

    },
    checkIn: {
      start: null,
      end: null,
    },
    tournament: {
      start: null,
      end: null,
    },
  });

  const [isPrivate, setTournamentPrivate] = useState(false);
  const [isInvisible, setTournamentInvisible] = useState(false);

  const [publicId, setPublicId] = useState('');
  const [status, setStatus] = useState('open');

  const [staffPermissions, setStaffPermissions] = useState(INITIAL_STAFF_PERMISSIONS);
  const [participantPermissions, setParticipantPermissions] = useState(INITIAL_PARTICIPANT_PERMISSIONS);

  const [showRequestPopup, setShowRequestPopup] = useState(false);
  const [showNoAuthPopup, setShowNoAuthPopup] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [contentToShow, setContentToShow] = useState('info');
  const [sidebarContent, setSidebarContent] = useState({
    type: '',
    data: {},
  });

  // only needed for check-in
  const [checkInCount, setCheckInCount] = useState({
    amountCheckedIn: 0,
    amountTotal: 0,
  });

  const { get } = useAPI();

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation(['general', 'errors']);
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  // reset sidebar if were not showing tournament info
  useLayoutEffect(() => {
    if (currentPhase !== 'in_progress') {
      setSidebarContent({
        type: '',
        data: {},
      });
      dispatch(setShowSidebar(true));
      return;
    }

    if (contentToShow !== 'info') {
      setSidebarContent({
        type: '',
        data: {},
      });
      dispatch(setShowSidebar(true));
    }
  }, [contentToShow, currentPhase]);

  // hide main sidebar to show the tournament sidebar
  useLayoutEffect(() => {
    if (sidebarContent.type) {
      dispatch(setShowSidebar(false));
    } else {
      dispatch(setShowSidebar(true));
    }

    return () => {
      dispatch(setShowSidebar(true));
    };
  }, [sidebarContent]);

  const evaluatePermissions = (roles) => {
    if (!roles) return;
    const { staff: staffMember, participant: _participant } = roles;

    if (!staffMember && !_participant) return;

    if (staffMember) {
      const canUpdateTournamentInfo = hasStaffPermission(staffMember, 'updateTournamentInfo');
      const canDeleteTournament = hasStaffPermission(staffMember, 'deleteTournament');
      const canInviteStaff = hasStaffPermission(staffMember, 'inviteStaff');
      const canKickAdmin = hasStaffPermission(staffMember, 'kickAdmin');
      const canKickStaffMember = hasStaffPermission(staffMember, 'kickStaffMember');
      const canKickParticipant = hasStaffPermission(staffMember, 'kickParticipant');
      const canDisqualifyParticipant = hasStaffPermission(staffMember, 'disqualifyParticipant');
      const canSetStaffPermissions = hasStaffPermission(staffMember, 'setStaffPermissions');
      const canSetStaffTournamentRole = hasStaffPermission(staffMember, 'setStaffTournamentRole');
      const canBanParticipant = hasStaffPermission(staffMember, 'banParticipant');
      const canManageMatches = hasStaffPermission(staffMember, 'manageMatches');
      const canManageBracket = hasStaffPermission(staffMember, 'manageBracket');

      setStaffPermissions({
        ...staffPermissions,
        canUpdateTournamentInfo,
        canDeleteTournament,
        canInviteStaff,
        canKickAdmin,
        canKickStaffMember,
        canKickParticipant,
        canDisqualifyParticipant,
        canSetStaffPermissions,
        canSetStaffTournamentRole,
        canBanParticipant,
        canManageMatches,
        canManageBracket,
      });
    }

    if (_participant) {
      const canCheckIn = hasParticipantPermission(_participant, 'checkIn');
      const canSetGameOutcome = hasParticipantPermission(_participant, 'setGameOutcome');
      const canDeregister = hasParticipantPermission(_participant, 'deregister');

      setParticipantPermissions({
        canCheckIn,
        canSetGameOutcome,
        canDeregister,
      });
    }
  };

  useEffect(() => {
    if (isAuthLoading || params.id === publicId) return undefined;

    setError('');

    const loadTournament = async () => {
      try {
        setLoading(true);

        const { id } = params;

        if (!id) {
          navigate(TOURNAMENT_LIST);
        }

        let response;

        if (!isAuthenticated) {
          response = await get(`/tournaments/public/${id}`);
        } else {
          response = await get(`/tournaments/${id}`);
        }

        const _tournament = response.tournament;
        const _roles = response.roles;
        const _participant = response.participant;

        evaluatePermissions(_roles);

        setPublicId(_tournament.publicId);
        setInfo((prevInfo) => ({
          ...prevInfo,
          ..._tournament.info,
        }));
        setGame((prevGame) => ({
          ...prevGame,
          ..._tournament.game,
        }));
        setDeadlines((prevDeadlines) => ({
          ...prevDeadlines,
          ..._tournament.deadlines,
        }));

        setParticipant((prevParticipant) => ({
          ...prevParticipant,
          ..._participant,
        }));

        setTournamentSettings(_tournament.tournamentSettings);
        setCurrentPhase(_tournament.currentPhase);
        setStaff(_tournament.staff);
        // setBracket(_tournament.bracket);

        setLoading(false);
      } catch (e) {
        console.error(e);
        // TODO: if private, set different error
        setError(t('errors:data_load_error'));
      }
    };

    loadTournament();

    // load tournament every minute
    const interval = setInterval(async () => {
      await loadTournament();
    }, 60000);

    return () => clearInterval(interval);
  }, [params, publicId, isAuthenticated, isAuthLoading]);

  const _setInfo = useCallback((newInfo) => {
    setInfo((prevInfo) => ({
      ...prevInfo,
      ...newInfo,
    }));
  }, []);

  const _setCurrentPhase = useCallback((newPhase) => {
    setCurrentPhase(newPhase);
  }, []);

  const _setTournamentType = useCallback((newTournamentType) => {
    setTournamentSettings((prevTournamentSettings) => ({
      ...prevTournamentSettings,
      tournamentType: newTournamentType,
    }));
  }, []);

  const _setBestOfMatches = useCallback((_bestOfMatches) => {
    setTournamentSettings((prevTournamentSettings) => ({
      ...prevTournamentSettings,
      bestOfMatches: _bestOfMatches,
    }));
  }, []);

  const _setBestOfFinals = useCallback((_bestOfFinals) => {
    setTournamentSettings((prevTournamentSettings) => ({
      ...prevTournamentSettings,
      bestOfFinals: _bestOfFinals,
    }));
  }, []);

  const _setMaxParticipants = useCallback((newMaxParticipants) => {
    setTournamentSettings((prevTournamentSettings) => ({
      ...prevTournamentSettings,
      maxParticipants: newMaxParticipants,
    }));
  }, []);

  const _setMaxGroups = useCallback((newMaxGroups) => {
    setTournamentSettings((prevTournamentSettings) => ({
      ...prevTournamentSettings,
      maxGroups: newMaxGroups,
    }));
  }, []);

  const _setCheckInDeadline = useCallback((newDeadline) => {
    setDeadlines((prevDeadlines) => ({
      ...prevDeadlines,
      checkIn: newDeadline,
    }));
  }, []);

  const _setRegistrationDeadline = useCallback((newDeadline) => {
    setDeadlines((prevDeadlines) => ({
      ...prevDeadlines,
      registration: newDeadline,
    }));
  }, []);

  const _setTournamentDeadline = useCallback((newDeadline) => {
    setDeadlines((prevDeadlines) => ({
      ...prevDeadlines,
      tournament: newDeadline,
    }));
  }, []);

  const _setPlatform = useCallback((newPlatform) => {
    setGame((prevGame) => ({
      ...prevGame,
      platform: newPlatform,
    }));
  }, []);

  const setStaffMember = useCallback((updatedMember) => {
    setStaff((prevStaff) => prevStaff.map((staffMember) => {
      if (staffMember.playerId === updatedMember.playerId) {
        return {
          ...staffMember,
          ...updatedMember,
        };
      }

      return staffMember;
    }));
  }, []);

  const removeStaff = useCallback((staffId) => {
    setStaff((prevStaff) => prevStaff.filter((staffMember) => staffMember.playerId !== staffId));
  }, []);

  const removeParticipant = useCallback((participantId) => {
    setParticipants((prevParticipants) => prevParticipants.filter((_participant) => _participant.publicId !== participantId));

    // if the current participant is removed, reset the participant state
    setParticipant((prevParticipant) => {
      if (prevParticipant.publicId === participantId) {
        return {
          name: '',
          cardPic: '',
          publicId: '',
          participantPublicId: '',
          participantType: '',
          joinedAt: new Date(),
          isCheckedIn: false,
        };
      }

      return prevParticipant;
    });
  }, []);

  const reevaluatePermissions = useCallback((newRoles) => {
    evaluatePermissions(newRoles);
  }, []);

  const renderedContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="is-min-fullheight is-flex has-content-centered flex-direction-column">
          <p className="mt-2 has-text-weight-semibold is-size-5">
            {error || t('general:loading')}
          </p>
        </div>
      );
    }

    if (contentToShow === 'info') {
      const hasPartners = info.mainSponsor.name || info.organizer.name || info.sponsors.some((sponsor) => sponsor.name);

      return (
        <section>
          <div className="">
            <TournamentHeader />

            <div className="mt-6">
              <div className="columns">
                <div className="column has-overflow-hidden p-0">
                  <TournamentPhaseHandler />
                </div>
                {
                  hasPartners && (
                    <div className="column is-narrow has-width-180 py-0">
                      <TournamentPartnerDisplay />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </section>
      );
    }

    if (contentToShow === 'setup') {
      return (<TournamentEditor />);
    }

    if (contentToShow === 'admin') {
      return (
        <section>
          <div className="">
            <TournamentAdminPanel />
          </div>
        </section>
      );
    }

    return null;
  }, [contentToShow, isLoading, error, t]);

  const values = useMemo(() => ({
    info,
    game,
    tournamentSettings,
    currentPhase,

    participant,
    participants,

    deadlines,
    publicId: params.id,
    isPrivate,
    isInvisible,
    staff,

    sidebarContent,
    setSidebarContent,

    staffPermissions,
    participantPermissions,
    showRequestPopup,
    showNoAuthPopup,
    status,
    checkInCount,

    reevaluatePermissions,

    removeStaff,
    removeParticipant,

    setShowRequestPopup,
    setShowNoAuthPopup,

    setContentToShow,
    setGame,

    setStaffMember,
    setParticipants,
    setParticipant,

    setTournamentType: _setTournamentType,
    setBestOfMatches: _setBestOfMatches,
    setBestOfFinals: _setBestOfFinals,
    setMaxParticipants: _setMaxParticipants,
    setMaxGroups: _setMaxGroups,

    setCheckInDeadline: _setCheckInDeadline,
    setRegistrationDeadline: _setRegistrationDeadline,
    setTournamentDeadline: _setTournamentDeadline,
    setPlatform: _setPlatform,
    setInfo: _setInfo,
    setCurrentPhase: _setCurrentPhase,
    setTournamentPrivate,
    setTournamentInvisible,
    setCheckInCount,
  }), [
    info,
    game,
    tournamentSettings,
    currentPhase,

    participant,
    participants,

    deadlines,
    params.id,
    isPrivate,
    isInvisible,
    staff,

    sidebarContent,
    setSidebarContent,

    staffPermissions,
    participantPermissions,
    showRequestPopup,
    showNoAuthPopup,
    status,
    checkInCount,

    reevaluatePermissions,

    removeStaff,
    removeParticipant,

    setShowRequestPopup,
    setShowNoAuthPopup,

    setContentToShow,

    setStaffMember,
    setParticipants,
    setParticipant,

    _setTournamentType,
    _setBestOfMatches,
    _setBestOfFinals,
    _setMaxParticipants,
    _setMaxGroups,

    _setCheckInDeadline,
    _setRegistrationDeadline,
    _setTournamentDeadline,
    _setPlatform,
    _setInfo,
    _setCurrentPhase,
    setTournamentPrivate,
    setTournamentInvisible,
    setCheckInCount,
  ]);

  return (
    <TournamentContext.Provider value={values}>

      <TournamentSocketProvider>
        <div className="is-min-fullheight">
          <div className="columns">
            <div
              className="column has-overflow-hidden flex-grow"
            >
              {renderedContent}
            </div>
            <TournamentSidebar />
          </div>

        </div>
      </TournamentSocketProvider>
    </TournamentContext.Provider>
  );
}

export const useTournament = () => {
  const data = useContext(TournamentContext);

  return data;
};

export default TournamentPage;
