import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTournament } from 'components/tournaments/TournamentPage';

// Components
import ChatLayout from 'components/utils/ChatLayout';

function TournamentChatHandler() {
  const { t } = useTranslation(['general']);

  const { playerId } = useSelector((state) => state.player.player);

  const {
    publicId, sidebarContent,
  } = useTournament();

  // if we dont have any data for the match yet, dont show anything
  if (!sidebarContent.data.result) return null;

  const { publicId: matchId } = sidebarContent.data;

  return (
    <div
      className="pt-3 has-background-dark-blue has-text-weight-light is-flex flex-direction-column is-relative"
      style={{ height: 'calc(100vh - 68px)' }}
    >
      <div className="has-fullheight is-flex flex-direction-column p-0">
        <div className="has-text-centered mt-1">
          <h2 className="has-text-standard is-size-5 has-text-weight-light">
            {t('general:match_chat')}
          </h2>
        </div>
        <div className="is-flex is-relative has-flex-1 has-overflow-hidden pt-1">
          <ChatLayout
            channel="tournament_match"
            id={`${publicId}:${matchId}`}
            allowImageUpload
          />
        </div>
      </div>

    </div>

  );
}

export default TournamentChatHandler;
